import { Box, Container } from '@mui/material'
import React, { FC } from 'react'
import Header from '../Header/Header'

interface LayoutProps {
  children: React.ReactNode
}

const Layout: FC<LayoutProps> = ({ children }) => {
  return (
        <>
            <Header />
            <Box>
                <Container maxWidth="lg" sx={{ paddingY: 4, paddingX: 2 }}>
                    {children}
                </Container>
            </Box>
        </>
  )
}

export default Layout
