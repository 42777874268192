import Menu from '../Menu/Menu'
import { Paper, Stack, Typography } from '@mui/material'
import React from 'react'
import { useLocation } from 'react-router-dom'

const Header = () => {
  const { key, pathname } = useLocation()
  const title = key !== 'default' ? pathname.substring(1).replaceAll('-', '/').split('/').map(text => `${text.substring(0, 1).toUpperCase()}${text.substring(1)}`).join(' ') : 'Not Found';
  return (
        <>
            <Menu />
            <Paper elevation={3} square>
                <Stack sx={{
                  height: 200,
                  backgroundColor: '#03a9f4',
                  background: 'linear-gradient(90deg, rgba(34,143,195,1) 0%, rgba(45,175,253,1) 100%)',
                  color: '#fff',
                  justifyContent: 'center',
                  paddingTop: 8
                }}>
                    <Typography variant="h3">
                        {title || 'Home'}
                    </Typography>
                </Stack>
            </Paper>
        </>
  )
}

export default Header
