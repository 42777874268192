import { Button, Stack, Typography } from '@mui/material'
import Project from '../../components/Project/Project'
import React from 'react'
import { Link } from 'react-router-dom'
import * as state from '../../state/state'
import Github from '../../components/Github/Github'

const Home = () => {
  const projects = [...state.projects.sort(() => 0.5 - Math.random())]
  projects.length = 3
  return (
        <Stack gap={4}>
            <Stack gap={2}>
                <Stack direction="row" flexWrap="wrap" gap={2} alignItems="end">
                    <Typography variant="h4" align="left">About</Typography>
                    <Link to="/about" style={{
                      lineHeight: 2
                    }}>read more</Link>
                </Stack>
                <Typography align="left">
                    Full stack developer with a degree in science with an emphasis on computer science from the Open University who specializes in developing web applications with years of experience in the field, today I mainly deal with the worlds of javascript with technologies such as react, nodeJS
                </Typography>
            </Stack>
            <Stack gap={4}>
                <Stack direction="row" flexWrap="wrap" gap={2} alignItems="end">
                    <Typography variant="h4" align="left">Random Projects</Typography>
                    <Link to="/projects" style={{
                      lineHeight: 2
                    }}>see more</Link>
                </Stack>
                <Stack direction="row" flexWrap="wrap" gap={2}>
                    {projects.map(({ name, description, image, github, preview, document }) => <Project key={name} name={name} description={description} image={image} github={github} preview={preview} document={document} />)}
                </Stack>
                <Typography variant="h5" align="left">Categories</Typography>
                <Stack direction="row" gap={2}>
                    {state.categories.map(category => <Link key={category} to={`/projects/${category}`}><Button variant="outlined" >{category}</Button></Link>)}
                </Stack>
            </Stack>
            <Github />
        </Stack>
  )
}

export default Home
