import blackjack from '../asset/blackjack.jpg'
import ticTacToe from '../asset/ticTacToe.png'
import trivia from '../asset/trivia.jpg'
import todoList from '../asset/todoList.png'
import quiz from '../asset/quiz.png'
import java from '../asset/java.png'
import cProgramming from '../asset/c-programming.jpg'

export const categories = ['javascript', 'react', 'nodejs', 'c', 'java']
export const projects = [
  {
    name: 'Blackjack Game Online',
    description: 'Blackjack game which is built in javascript 6+',
    category: 'javascript',
    image: blackjack,
    github: 'https://github.com/yuvalMarcus/BlackjackGameOnline',
    preview: 'http://yuvalmarcus.co/projects/javascript/es6plus/blackjack/',
    document: ''
  },
  {
    name: 'TicTacToe Game Online',
    description: 'Tic Tac Toe game which is built in javascript 5',
    category: 'javascript',
    image: ticTacToe,
    github: 'https://github.com/yuvalMarcus/TriviaGameOnline',
    preview: 'http://yuvalmarcus.co/projects/javascript/es5/tic-tac-toe/',
    document: ''
  },
  {
    name: 'Trivia Game Online',
    description: 'Trivia game which is built in javascript 5',
    category: 'javascript',
    image: trivia,
    github: 'https://github.com/yuvalMarcus/TicTacToeGameOnline',
    preview: 'http://yuvalmarcus.co/projects/javascript/es5/words-game/',
    document: ''
  },
  {
    name: 'Calendar',
    description: 'Calendar which is built in react',
    category: 'react',
    image: 'https://www.groovypost.com/wp-content/uploads/2022/09/calendar-example.png',
    github: 'https://github.com/yuvalMarcus/Calendar-React',
    preview: 'http://yuvalmarcus.co/projects/react/calendar/',
    document: ''
  },
  {
    name: 'Todo List',
    description: 'Todo List which is built in react',
    category: 'react',
    image: todoList,
    github: 'https://github.com/yuvalMarcus/TodoList-React',
    preview: 'http://yuvalmarcus.co/projects/react/todolist/',
    document: ''
  },
  {
    name: 'Quiz',
    description: 'quiz app which is built in nodejs',
    category: 'nodejs',
    image: quiz,
    github: 'https://github.com/yuvalMarcus/Quiz-Nodejs-Mongodb',
    preview: 'http://52.30.179.219:8282/',
    document: ''
  },
  {
    name: 'Mathematical Group',
    description: 'Circular linked list contains n order Fibonacci series.',
    category: 'java',
    image: java,
    github: 'https://github.com/yuvalMarcus/MathematicalGroup',
    preview: '',
    document: 'http://yuvalmarcus.co/pdf/java/mathematicalgroup.pdf'
  },
  {
    name: 'Assembler',
    description: 'Assembler, for assembly language defined in document',
    category: 'c',
    image: cProgramming,
    github: 'https://github.com/yuvalMarcus/Assembler',
    preview: '',
    document: 'http://yuvalmarcus.co/pdf/c/assembler.pdf'
  },
  {
    name: 'Matrix Pocket Calculator',
    description: 'Interactive pocket calculator to perform invoice operations on matrix.',
    category: 'c',
    image: cProgramming,
    github: 'https://github.com/yuvalMarcus/MatrixPocketCalculator',
    preview: '',
    document: 'http://yuvalmarcus.co/pdf/c/matrixpocketcalculator.pdf'
  },
  {
    name: 'Fibonacci Series',
    description: 'Circular linked list contains n order Fibonacci series.',
    category: 'c',
    image: cProgramming,
    github: 'https://github.com/yuvalMarcus/FibonacciSeries',
    preview: '',
    document: 'http://yuvalmarcus.co/pdf/c/fibonacciseries.pdf'
  }
]
