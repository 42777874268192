import { Button, Stack, Typography } from '@mui/material'
import Project from '../../components/Project/Project'
import React from 'react'
import { Link, useParams } from 'react-router-dom'
import * as state from '../../state/state'

const Projects = () => {
  const { category: selectedCategory } = useParams()
  const projects = (selectedCategory != null) ? state.projects.filter(({ category }) => category === selectedCategory) : state.projects
  return (
        <>
            <Stack gap={4}>
                <Stack direction="row" alignItems="center" gap={1}>
                    <Typography>Categories : </Typography>
                    <Stack direction="row" gap={2}>
                        <Link to={'/projects'}><Button variant="outlined" >All</Button></Link>
                        {state.categories.map(category => <Link key={category} to={`/projects/${category}`}><Button variant="outlined" >{category}</Button></Link>)}
                    </Stack>
                </Stack>
                <Stack justifyContent="center">
                    <Stack direction="row" flexWrap="wrap" gap={2}>
                        {projects.map(({ name, description, image, github, preview, document }) => <Project key={name} name={name} description={description} image={image} github={github} preview={preview} document={document} />)}
                    </Stack>
                </Stack>
            </Stack>
        </>
  )
}

export default Projects
