import { Box, Stack, Typography, Link } from '@mui/material'
import React from 'react'

const ContactUs = () => {
  return (
        <Stack gap={4}>
                <Box>
                <Typography variant="h4" align="left" mb={2}>
                    Contact Us
                </Typography>
                <Typography align="left">
                    Do you have an interesting job offer or just a question, you can contact me through a number of ways.
                </Typography>
            </Box>
            <Stack gap={2}>
                <Box>
                    <Typography variant="h5" align="left">Linkedin</Typography>
                    <Typography align="left">
                        <Link target="_blank" href="https://www.linkedin.com/in/yuval-marcus-2b215315b/">https://www.linkedin.com/in/yuval-marcus-2b215315b</Link>
                    </Typography>
                </Box>
                <Box>
                    <Typography variant="h5" align="left">Email</Typography>
                    <Typography align="left">
                        <Link target="_blank" href="mailto:yuval.marcus.90@gmail.com">yuval.marcus.90@gmail.com</Link>
                    </Typography>
                </Box>
            </Stack>
        </Stack>
  )
}

export default ContactUs
