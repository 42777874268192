import { Box, Stack, Typography } from '@mui/material'
import githubLogo from '../../asset/github-logo.png'
import { Link } from 'react-router-dom'
import React from 'react'

const Github = () => {
  return (
        <Box>
            <Typography variant="h4" align="left" mb={2}>
                Github
            </Typography>
            <Stack direction="row" gap={2} alignItems="center">
                <img
                    src={githubLogo}
                    width={100}
                    alt={'alt'}
                    loading="lazy"
                />
                <Typography align="left">
                    For all the projects and more, you can visit my profile on github.
                    <br />
                    <Link to={'https://github.com/yuvalMarcus'}>https://github.com/yuvalMarcus</Link>
                </Typography>
            </Stack>
        </Box>
  )
}

export default Github
