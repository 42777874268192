import React from 'react'
import './App.css'
import Projects from './screen/Projects/Projects'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import Home from './screen/Home/Home'
import Layout from './components/Layout/Layout'
import About from './screen/About/About'
import ContactUs from './screen/ContactUs/ContactUs'
import NotFound from "./screen/NotFound/NotFound";

const router = createBrowserRouter([
  {
    path: '/',
    element: <Layout><Home /></Layout>,
    errorElement: <Layout><NotFound /></Layout>
  },
  {
    path: '/projects',
    element: <Layout><Projects /></Layout>
  },
  {
    path: '/projects/:category',
    element: <Layout><Projects /></Layout>
  },
  {
    path: '/about',
    element: <Layout><About /></Layout>
  },
  {
    path: '/contact-us',
    element: <Layout><ContactUs /></Layout>
  }
])

const App = () => {
  return (
    <div className="App">
        <RouterProvider router={router} />
    </div>
  )
}

export default App
