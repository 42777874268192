import * as React from 'react'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import { Link } from 'react-router-dom'
import { Stack, Typography } from '@mui/material'

const styleLink = {
  color: 'white',
  fontWeight: 700,
  textDecoration: 'none'
}

const Menu = () => {
  return (
        <Box sx={{ position: 'fixed', width: '100%', zIndex: 10, flexGrow: 1 }}>
            <AppBar position="static" sx={{ backgroundColor: 'black' }}>
                <Toolbar>
                    <Stack direction="row" flexWrap="wrap" gap={2} flexGrow={1}>
                        <Typography>Yuval Marcus Portfolio</Typography>
                        <Link to='/' style={styleLink}>Home</Link>
                        <Link to='/projects' style={styleLink}>Projects</Link>
                        <Link to='/about' style={styleLink}>About</Link>
                        <Link to='/contact-us' style={styleLink}>Contact Us</Link>
                    </Stack>
                </Toolbar>
            </AppBar>
        </Box>
  )
}

export default Menu
