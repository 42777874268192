import { Box, List, ListItem, ListItemText, Stack, Typography } from '@mui/material'
import React from 'react'
import Github from '../../components/Github/Github'

const mainStack = [
  {
    title: 'Frontend Frameworks',
    content: 'react'
  },
  {
    title: 'UI Libraries',
    content: 'material-ui | tailwind'
  },
  {
    title: 'State Management',
    content: 'jotai | redux'
  },
  {
    title: 'Backend Frameworks',
    content: 'nodeJs'
  },
  {
    title: 'Backend Libraries',
    content: 'express | restJs | graphQl'
  },
  {
    title: 'Databases',
    content: 'postgres'
  }
]

const About = () => {
  return (
        <Stack gap={4}>
            <Box>
                <Typography variant="h4" align="left" mb={2}>
                    About Me
                </Typography>
                <Typography align="left">
                    Full stack developer with a degree in science with an emphasis on computer science from the Open University who specializes in developing web applications with years of experience in the field, today I mainly deal with the worlds of javascript with technologies such as react, nodeJS
                </Typography>
            </Box>
            <Box width="100%">
                <Typography variant="h4" align="left">
                    Main Stack
                </Typography>
                <List sx={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  gap: 2
                }}>
                    {mainStack.map(({ title, content }) => (
                        <ListItem key={title} sx={{ width: 'fit-content' }}>
                            <ListItemText
                                primary={title}
                                secondary={content}
                            />
                        </ListItem>
                    ))}
                </List>
            </Box>
            <Github />
        </Stack>
  )
}

export default About
