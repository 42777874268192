import {Typography} from "@mui/material";

const NotFound = () => {
    return (
        <Typography variant="h4">
            Page not found
        </Typography>
    )
}

export default NotFound;