import * as React from 'react'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardMedia from '@mui/material/CardMedia'
import Typography from '@mui/material/Typography'
import { Button, CardActions } from '@mui/material'
import { type FC } from 'react'
import { Link } from 'react-router-dom'

interface ProjectProps {
  name?: string
  description?: string
  image?: string
  github?: string
  preview?: string
  document?: string
}

const Project: FC<ProjectProps> = ({ name, description, image, github, preview, document }) => {
  return (
        <Card sx={{ width: '30%' }}>
                <CardMedia
                    component="img"
                    height="140"
                    image={image}
                    alt="green iguana"
                />
                <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                        {name}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">{description}</Typography>
                </CardContent>
            <CardActions>
                {github && <Link to={github} target='_black'><Button size="small" color="primary">Github</Button></Link>}
                {preview && <Button size="small" color="primary">
                    Preview
                </Button>}
                {document && <Button size="small" color="primary">
                    Document
                </Button>}
            </CardActions>
        </Card>
  )
}

export default Project
